import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  TextField,
  Button,
  Typography,
} from '@material-ui/core';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Ajusta la altura para llenar toda la pantalla
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px', // Ancho máximo del formulario
  },
}));

function RegistroNumeros() {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    numero_ticket: '',
    nombre: '',
    apellido: '',
    telefono: '',
    fecha_apartado: '',
    fecha_pago: '',
    estado_id: 1,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/api/tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        // Mostrar el mensaje utilizando SweetAlert2
        Swal.fire({
          title: 'Éxito',
          text: data.message,
          icon: 'success',
        }).then(() => {
          // Limpiar los campos después de hacer clic en "OK"
          setFormData({
            numero_ticket: '',
            nombre: '',
            apellido: '',
            telefono: '',
            fecha_apartado: '',
            fecha_pago: '',
            estado_id: 1,
          });
        });
      } else {
        const data = await response.json();
        // Manejar errores aquí si es necesario
        Swal.fire({
          title: 'Error',
          text: data.message,
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      Swal.fire({
        title: 'Error de red',
        text: 'Ha ocurrido un error de red',
        icon: 'error',
      });
    }
  };

  return (
    <Container className={classes.formContainer}>
      <Typography variant="h4">Registrar Nuevo Número</Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          label="Número de Ticket"
          name="numero_ticket"
          variant="outlined"
          value={formData.numero_ticket}
          onChange={handleInputChange}
        />
        <TextField
          label="Nombre"
          name="nombre"
          variant="outlined"
          value={formData.nombre}
          onChange={handleInputChange}
        />
        <TextField
          label="Apellido"
          name="apellido"
          variant="outlined"
          value={formData.apellido}
          onChange={handleInputChange}
        />
        <TextField
          label="Teléfono"
          name="telefono"
          variant="outlined"
          value={formData.telefono}
          onChange={handleInputChange}
        />
        <TextField
          label="Fecha de Apartado"
          name="fecha_apartado"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData.fecha_apartado}
          onChange={handleInputChange}
        />
        <TextField
          label="Fecha de Pago"
          name="fecha_pago"
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={formData.fecha_pago}
          onChange={handleInputChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Registrar
        </Button>
      </form>
    </Container>
  );
}

export default RegistroNumeros;
