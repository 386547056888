import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import {
  Container,
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2), // Añadido para separar el contenido debajo
  },
  title: {
    fontSize: "36px",
    margin: "0 10px",
  },
  arrowButton: {
    backgroundColor: "transparent", // Para hacer que el botón sea transparente
  },
  arrowIcon: {
    fontSize: "48px", // Tamaño personalizado para los iconos de flechas
  },
}));

const Talonario = ({
  inicio,
  unavailableNumbers,
  paidNumbers,
  slickSlider,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Container>
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        className={classes.title}
      >
        GRAN RIFA
      </Typography>

      <Typography variant="h5" align="center" gutterBottom>
        TRIPLE TACHIRA 28/02/2024 10:10PM
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        1er Premio LAPTOP NUEVA 
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
         o su euivalente en efectivo
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
         2do Premio 300 USD en efectivo
      </Typography>
      <div className={classes.titleContainer}>
        <IconButton
          className={classes.arrowButton}
          onClick={() => slickSlider.current.slickPrev()}
        >
          <KeyboardDoubleArrowLeftOutlinedIcon className={classes.arrowIcon} />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          Talonario {inicio / 100 + 1} de 10
        </Typography>
        <IconButton
          className={classes.arrowButton}
          onClick={() => slickSlider.current.slickNext()}
        >
          <KeyboardDoubleArrowRightOutlinedIcon className={classes.arrowIcon} />
        </IconButton>
      </div>

      <Grid
        container
        spacing={isSmallScreen ? 1 : 2}
        style={{ marginBottom: "20px" }}
      >
        {Array.from({ length: 100 }, (_, i) => {
          const number = inicio + i;
          const isUnavailable = unavailableNumbers.includes(number) ;
          const isPaid = paidNumbers.includes(number) ;
          let backgroundColor = "white";
          let textColor = "black";

          // Dentro del bucle donde se establecen los colores de fondo
          if (isUnavailable && isPaid) {
            backgroundColor = "green"; // Número pagado y no disponible
            textColor = "white";
          } else if (isUnavailable && !isPaid) {
            backgroundColor = "yellow"; // Número no pagado y no disponible
            textColor = "black"; // Cambiar el texto a negro
          }

          return (
            <Grid item xs={isSmallScreen ? 4 : 1} key={i}>
              <Paper
                elevation={3}
                style={{
                  textAlign: "center",
                  padding: "10px",
                  backgroundColor: backgroundColor,
                  color: textColor,
                  fontFamily: '"Handjet", sans-serif',
                }}
              >
                {number.toString().padStart(3, "0")}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

const CarruselTalonarios = () => {
  const [unavailableNumbers, setUnavailableNumbers] = useState([]);
  const [paidNumbers, setPaidNumbers] = useState([]);
  const slickSlider = useRef(null);

  useEffect(() => {
    const fetchUnavailableNumbers = () => {
      fetch("/unavailableNumbers.txt?" + new Date().getTime())
        .then((response) => response.text())
        .then((data) => {
          const numbers = data.match(/\d+/g).map(Number);
          setUnavailableNumbers(numbers);
        })
        .catch((error) => {
          console.error(
            "Hubo un error al leer el archivo de números no disponibles:",
            error
          );
        });
    };

    const fetchPaidNumbers = () => {
      fetch("/paidNumbers.txt?" + new Date().getTime())
        .then((response) => response.text())
        .then((data) => {
          const numbers = data.match(/\d+/g).map(Number);
          setPaidNumbers(numbers);
        })
        .catch((error) => {
          console.error(
            "Hubo un error al leer el archivo de números pagados:",
            error
          );
        });
    };

    fetchUnavailableNumbers();
    fetchPaidNumbers();

    const intervalIdUnavailable = setInterval(fetchUnavailableNumbers, 2000);
    const intervalIdPaid = setInterval(fetchPaidNumbers, 2000);

    return () => {
      clearInterval(intervalIdUnavailable);
      clearInterval(intervalIdPaid);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings} ref={slickSlider}>
        {Array.from({ length: 10 }, (_, i) => (
          <Talonario
            inicio={i * 100}
            key={i}
            unavailableNumbers={unavailableNumbers}
            paidNumbers={paidNumbers}
            slickSlider={slickSlider}
          />
        ))}
      </Slider>
    </div>
  );
};

export default CarruselTalonarios;
