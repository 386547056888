import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CarruselTalonarios from './components/CarruselTalonarios';
import RegistroNumeros from './components/RegistroNumeros';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Handjet", sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<CarruselTalonarios/>} />
          <Route path="/register/new" element={<RegistroNumeros/>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
